import React, { useState } from 'react';
import { Link } from 'gatsby';
import * as styles from './ScholarshipCourses.module.scss';

const ScholarshipCourses = ({ screen, courses, slug, disable, ...props }) => {
    const [toggleSection, setToggleSection] = useState([
        false,
        false,
        false,
        false
    ]);

    const handleToggle = (section) => {
        var newArr = [...toggleSection];
        newArr[section] = !newArr[section];
        setToggleSection(newArr);
    };

    return (
        <div className={styles.coursesContainer}>
            {courses.map((course) => {
                return (
                    <div
                        className={
                            toggleSection[course.id]
                                ? styles.courseItemOpen
                                : styles.courseItem
                        }
                    >
                        <div className={styles.title}>
                            <h5>{course.title}</h5>

                            <Link
                                to={
                                    slug === 'neumann-fellowship'
                                        ? '/programme/' + slug + '/apply'
                                        : '/scholarship/' + slug + '/apply'
                                }
                                className={`${styles.linkContainer} ${
                                    disable
                                        ? styles.disabledLinkContainer
                                        : null
                                }`}
                                onClick={() =>
                                    localStorage.setItem(
                                        'chosenCourse',
                                        course.title.replace(
                                            'Certified Associate in ',
                                            ''
                                        )
                                    )
                                }
                            >
                                <div
                                    className={`${styles.link} ${
                                        disable ? styles.disabledLink : null
                                    }`}
                                >
                                    {screen !== 'xl' ? 'Apply' : 'Apply Now'}
                                </div>
                            </Link>
                        </div>

                        <div className={styles.courseContent}>
                            <div className={styles.keyFact}>
                                <span>Course Type</span>
                                <p>{course.type}</p>
                            </div>

                            <div className={styles.keyFact}>
                                <span>Duration</span>
                                <p>{course.duration}</p>
                            </div>

                            <div className={styles.keyFact}>
                                <span>Work load</span>
                                <p>{course.load}</p>
                            </div>

                            <p>{course.about}</p>

                            <div
                                className={styles.blueButton}
                                onClick={() => handleToggle(course.id)}
                            >
                                Read Less
                            </div>
                        </div>

                        <div className={styles.toggleSize}>
                            <div
                                className={styles.blueButton}
                                onClick={() => handleToggle(course.id)}
                            >
                                Read More
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ScholarshipCourses;
