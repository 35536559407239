// extracted by mini-css-extract-plugin
export var scholarshipDetailsContainer = "scholarshipDetails-module--scholarshipDetailsContainer--1lKez";
export var applyBtn = "scholarshipDetails-module--applyBtn--3ofjG";
export var headerContainer = "scholarshipDetails-module--headerContainer--21Zmj";
export var bg = "scholarshipDetails-module--bg--3WGJP";
export var bgContainer = "scholarshipDetails-module--bgContainer--151Rx";
export var title = "scholarshipDetails-module--title--14jdl";
export var textLine = "scholarshipDetails-module--textLine--I31kq";
export var line = "scholarshipDetails-module--line--2dD7_";
export var bgImg = "scholarshipDetails-module--bgImg--CcQU5";
export var opacity = "scholarshipDetails-module--opacity--2Hjw2";