// extracted by mini-css-extract-plugin
export var scholarshipInfoContainer = "ScholarshipInfo-module--scholarshipInfoContainer--1H7wG";
export var scholarsContainer = "ScholarshipInfo-module--scholarsContainer--CsNnB";
export var scholarItem = "ScholarshipInfo-module--scholarItem--1g9oo";
export var imageContainer = "ScholarshipInfo-module--imageContainer--1hhB3";
export var image = "ScholarshipInfo-module--image--3HElY";
export var shadow = "ScholarshipInfo-module--shadow--3NmV0";
export var texts = "ScholarshipInfo-module--texts--Z1hBI";
export var line = "ScholarshipInfo-module--line--2aLM3";
export var overviewContainer = "ScholarshipInfo-module--overviewContainer--3Km3k";
export var overviewItem = "ScholarshipInfo-module--overviewItem--U7LxK";
export var eligibilityList = "ScholarshipInfo-module--eligibilityList--Ql_9k";
export var eligibilityItem = "ScholarshipInfo-module--eligibilityItem--2IfvZ";
export var icon = "ScholarshipInfo-module--icon--3Yz5w";
export var tabContainer = "ScholarshipInfo-module--tabContainer--YcIfK";
export var tabsBg = "ScholarshipInfo-module--tabsBg--2hdFe";
export var tabs = "ScholarshipInfo-module--tabs--3S6KZ";
export var tab = "ScholarshipInfo-module--tab--2Hk5K";
export var tabChosen = "ScholarshipInfo-module--tabChosen--3UzOk";
export var content = "ScholarshipInfo-module--content--1mXyJ";
export var details = "ScholarshipInfo-module--details--xzwQw";
export var keyInfo = "ScholarshipInfo-module--keyInfo--3-fkl";
export var infoBg = "ScholarshipInfo-module--infoBg--3DJnK";
export var toggle = "ScholarshipInfo-module--toggle--1ad7B";
export var infoBox = "ScholarshipInfo-module--infoBox--3wBnz";
export var imgContainer = "ScholarshipInfo-module--imgContainer--1HRuL";
export var img = "ScholarshipInfo-module--img--2ZXAk";
export var fact = "ScholarshipInfo-module--fact--1NZYm";
export var divider = "ScholarshipInfo-module--divider--3NiCg";
export var chevron = "ScholarshipInfo-module--chevron--1sJsY";
export var turn = "ScholarshipInfo-module--turn--3L-vt";
export var open = "ScholarshipInfo-module--open--1_o1x";