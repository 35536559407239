import React, { useState, useEffect } from 'react';
import * as styles from './ScholarshipInfo.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IoSchool } from '@react-icons/all-files/io5/IoSchool';
import { FaMoneyBillWave } from '@react-icons/all-files/fa/FaMoneyBillWave';
import { FaBookOpen } from '@react-icons/all-files/fa/FaBookOpen';
import { BsFillBriefcaseFill } from '@react-icons/all-files/bs/BsFillBriefcaseFill';
import { BsFillPeopleFill } from '@react-icons/all-files/bs/BsFillPeopleFill';
import { IoChevronDownCircleOutline } from '@react-icons/all-files/io5/IoChevronDownCircleOutline';
import { ImLocation2 } from '@react-icons/all-files/im/ImLocation2';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { FaClock } from '@react-icons/all-files/fa/FaClock';

import ScholarshipCourses from './ScholarshipCourses';

const ScholarshipInfo = ({
    tabSelected,
    setTabSelected,
    scholarship,
    ...props
}) => {
    const [toggleKeyInfo, setToggleKeyInfo] = useState(false);
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getCoursesList = () => {
        const newArr = scholarship.courses.map((course) => {
            if (course.title.includes('Certified Associate')) {
                return course.title.replace('Certified Associate in ', '');
            } else {
                return course.title;
            }
        });

        if (newArr.length >= 4) {
            const slicedArr = newArr.slice(0, 3);
            slicedArr.push('And more ...');
            return slicedArr;
        } else {
            return newArr;
        }
    };

    const getProvidersList = () => {
        const newArr = [...scholarship.provider];

        if (newArr.length >= 3) {
            const slicedArr = newArr.slice(0, 2);
            slicedArr.push('And more ...');
            return slicedArr;
        } else {
            return newArr;
        }
    };

    const showScholarshipDetails = () => {
        if (tabSelected === 1) {
            return (
                <div className={styles.overviewContainer}>
                    {scholarship.overview.map((item) => {
                        return (
                            <div className={styles.overviewItem}>
                                <h1>{item.title}</h1>
                                {item.description.map((para) => {
                                    return <p>{para}</p>;
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        } else if (tabSelected === 2) {
            return (
                <div>
                    <h1>Eligibility</h1>
                    <div className={styles.eligibilityList}>
                        {scholarship.eligibility.map((item) => {
                            return (
                                <div className={styles.eligibilityItem}>
                                    <div className={styles.icon}></div>
                                    <p>{item}</p>
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles.line}></div>

                    <h1>Selection Process</h1>
                    <div className={styles.eligibilityList}>
                        {scholarship.selection.map((item) => {
                            return (
                                <div className={styles.eligibilityItem}>
                                    <div className={styles.icon}></div>
                                    <span>{item.title}</span>
                                    {screen === 'xs' ? <br /> : null}
                                    <p>{item.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else if (tabSelected === 3) {
            return (
                <div>
                    <h1>Courses</h1>
                    <ScholarshipCourses
                        screen={screen}
                        courses={scholarship.courses}
                        slug={scholarship.slug}
                        disable={
                            scholarship.name === 'DSF FutureMakers Scholarship'
                                ? true
                                : false
                        }
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <h1>Our Scholars</h1>
                    <div className={styles.scholarsContainer}>
                        {scholarship.scholars.map((scholar) => {
                            return (
                                <div className={styles.scholarItem}>
                                    <div className={styles.imageContainer}>
                                        <div className={styles.shadow}></div>

                                        <GatsbyImage
                                            image={getImage(scholar.imgUrl)}
                                            alt={scholar.name}
                                            className={styles.image}
                                            imgStyle={
                                                screen !== 'xs'
                                                    ? { borderRadius: '10px' }
                                                    : { borderRadius: '50%' }
                                            }
                                            style={
                                                screen !== 'xs'
                                                    ? { borderRadius: '10px' }
                                                    : { borderRadius: '50%' }
                                            }
                                        />
                                    </div>

                                    <div className={styles.texts}>
                                        <h5>{scholar.name}</h5>
                                        <p>{scholar.about}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    };

    const showKeyInfo = () => {
        if (!toggleKeyInfo && screen === 'small') {
            setToggleKeyInfo(true);
        } else if (!toggleKeyInfo && screen === 'xs') {
            setToggleKeyInfo(true);
        } else {
            setToggleKeyInfo(false);
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());

        if (screen === 'large' || screen === 'xl') {
            setToggleKeyInfo(false);
        }
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <div className={styles.scholarshipInfoContainer}>
            <div className={styles.details} id="scholarship-details-in-page">
                {showScholarshipDetails()}
            </div>

            <div className={styles.tabContainer}>
                <div className={styles.tabsBg}>
                    <div className={styles.tabs}>
                        <div
                            className={
                                tabSelected == 1 ? styles.tabChosen : styles.tab
                            }
                            onClick={() => setTabSelected(1)}
                        >
                            Overview
                        </div>
                        <div
                            className={
                                tabSelected == 2 ? styles.tabChosen : styles.tab
                            }
                            onClick={() => setTabSelected(2)}
                        >
                            Eligibility
                        </div>
                        <div
                            className={
                                tabSelected == 3 ? styles.tabChosen : styles.tab
                            }
                            onClick={() => setTabSelected(3)}
                        >
                            Courses
                        </div>

                        {scholarship.name === 'DSF FutureMakers Scholarship' ? (
                            <div
                                className={
                                    tabSelected == 4
                                        ? styles.tabChosen
                                        : styles.tab
                                }
                                onClick={() => setTabSelected(4)}
                            >
                                Our Scholars
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className={styles.keyInfo}>
                <div className={styles.infoBg}>
                    <div
                        className={styles.toggle}
                        onClick={() => showKeyInfo()}
                    >
                        Key Information
                        <IoChevronDownCircleOutline
                            className={` ${styles.chevron} ${
                                toggleKeyInfo ? styles.turn : null
                            }`}
                        />
                    </div>

                    <div className={styles.shadow}></div>

                    <div
                        className={` ${styles.infoBox} ${
                            toggleKeyInfo ? styles.open : null
                        } `}
                    >
                        <div className={styles.imgContainer}>
                            <GatsbyImage
                                image={getImage(scholarship.smallImgURL)}
                                alt={scholarship.name}
                                className={styles.img}
                                imgStyle={{ borderRadius: '10px' }}
                            />
                        </div>

                        <div className={styles.fact}>
                            <IoSchool className={styles.icon} />

                            <div className={styles.texts}>
                                <span>Course Provider</span>
                                <p>
                                    {getProvidersList().map((provider) => {
                                        return <div>{provider}</div>;
                                    })}
                                </p>
                            </div>
                        </div>

                        <div className={styles.fact}>
                            <FaBookOpen className={styles.icon} />

                            <div className={styles.texts}>
                                <span>Courses Offered</span>
                                <p>
                                    {getCoursesList().map((course) => {
                                        return <div>{course}</div>;
                                    })}
                                </p>
                            </div>
                        </div>

                        <div className={styles.fact}>
                            <FaClock className={styles.icon} />

                            <div className={styles.texts}>
                                <span>Application Deadline</span>
                                <p>{scholarship.details.deadline}</p>
                            </div>
                        </div>

                        <div className={styles.fact}>
                            <BsFillPeopleFill className={styles.icon} />

                            <div className={styles.texts}>
                                <span>Upcoming Cohorts</span>
                                <p>{scholarship.details.cohorts}</p>
                            </div>
                        </div>

                        {scholarship.name ===
                        'DSF FutureMakers Scholarship' ? null : (
                            <div>
                                <div className={styles.fact}>
                                    <FaRegCalendarAlt className={styles.icon} />

                                    <div className={styles.texts}>
                                        <span>Programme Duration</span>
                                        <p>{scholarship.details.duration}</p>
                                    </div>
                                </div>

                                <div className={styles.fact}>
                                    <ImLocation2 className={styles.icon} />

                                    <div className={styles.texts}>
                                        <span>Fellowship Location</span>
                                        <p>
                                            {
                                                scholarship.details
                                                    .fellowshipLocation
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.fact}>
                                    <BsFillBriefcaseFill
                                        className={styles.icon}
                                    />

                                    <div className={styles.texts}>
                                        <span>Placement Location (if any)</span>
                                        <p>
                                            {
                                                scholarship.details
                                                    .placementLocation
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScholarshipInfo;
