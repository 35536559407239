import React, { useState } from 'react';
import { graphql } from 'gatsby';
import * as styles from './scholarshipDetails.module.scss';
import Layout from '../components/Layout';
import ScholarshipInfo from '../components/ScholarshipInfo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'react-scroll';

const ScholarshipDetails = ({ data }) => {
    const scholarship = data.scholarshipsJson;
    const [openCourse, setOpenCourse] = useState(false);
    const [tabSelected, setTabSelected] = useState(1);

    const handleClick = () => {
        setTabSelected(3);

        window.scrollTo({
            x: 0,
            y: 100,
            behaviour: 'smooth'
        });
    };

    return (
        <Layout darkBg={true}>
            <div className={styles.scholarshipDetailsContainer}>
                <div className={styles.headerContainer}>
                    <div className={styles.bg}>
                        <div className={styles.bgContainer}>
                            <GatsbyImage
                                image={getImage(scholarship.smallImgURL)}
                                alt={scholarship.name}
                                placeholder="blurred"
                                className={styles.bgImg}
                            />
                            <div className={styles.opacity}></div>
                        </div>

                        <div className={styles.title}>
                            <div className={styles.textLine}>
                                <h6>
                                    {scholarship.name ===
                                    'The Neumann Fellowship'
                                        ? 'FELLOWSHIP'
                                        : 'SCHOLARSHIP'}
                                </h6>
                                <div className={styles.line}></div>
                            </div>

                            <h1>{scholarship.name}</h1>

                            <Link
                                className={styles.applyBtn}
                                onClick={() => setTabSelected(3)}
                                to="scholarship-details-in-page"
                                smooth={true}
                            >
                                Apply Now
                            </Link>
                        </div>
                    </div>
                </div>
                <ScholarshipInfo
                    scholarship={scholarship}
                    openCourse={openCourse}
                    setOpenCourse={setOpenCourse}
                    tabSelected={tabSelected}
                    setTabSelected={setTabSelected}
                />
            </div>
        </Layout>
    );
};

export default ScholarshipDetails;

export const scholarshipsQuery = graphql`
    query($slug: String!) {
        scholarshipsJson(slug: { eq: $slug }) {
            name
            about
            overview {
                title
                description
            }
            slug
            courses {
                about
                duration
                load
                title
                type
                id
            }
            details {
                duration
                cohorts
                fellowshipLocation
                placementLocation
                deadline
            }
            eligibility
            provider
            scholars {
                about
                imgUrl {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
                name
            }
            selection {
                description
                title
            }
            smallImgURL {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }
`;
