// extracted by mini-css-extract-plugin
export var coursesContainer = "ScholarshipCourses-module--coursesContainer--2Jphr";
export var linkContainer = "ScholarshipCourses-module--linkContainer--3vfPe";
export var disabledLinkContainer = "ScholarshipCourses-module--disabledLinkContainer--rP9yi";
export var courseItem = "ScholarshipCourses-module--courseItem--_GOE3";
export var courseItemOpen = "ScholarshipCourses-module--courseItemOpen--2CaTO";
export var title = "ScholarshipCourses-module--title--1DzyM";
export var link = "ScholarshipCourses-module--link--1XvKm";
export var disabledLink = "ScholarshipCourses-module--disabledLink--3GfOD";
export var courseContent = "ScholarshipCourses-module--courseContent--3aDw5";
export var keyFact = "ScholarshipCourses-module--keyFact--1GaM6";
export var blueButton = "ScholarshipCourses-module--blueButton--XaHCm";
export var toggleSize = "ScholarshipCourses-module--toggleSize--3xKCG";
export var arrow = "ScholarshipCourses-module--arrow--1Bt8T";